@import 'action-menu/base';
@import 'app/base';
@import 'auth/base';
@import 'block/base';
@import 'col/base';
@import 'cultivation/base';
@import 'dashboard/base';
@import 'faq/base';
@import 'form/base';
@import 'graph/base';
@import 'img/base';
@import 'inventory/base';
@import 'maintenance/base';
@import 'notification/base';
@import 'page/base';
@import 'popup/base';
@import 'pos/base';
@import 'reports/base';
@import 'sandbox/base';
@import 'steps/base';
@import 'table/base';
@import 'widget/base';
@import 'delivery-management/base';

@import 'smokescreen';

body,
input {
  @extend .ss-pro;
}

body {
  overflow: hidden;
}

.main-container {
  // position: relative;
  // overflow-y: scroll;
  // width: 100%;
  // height: 100%;
  // background-color: #f5f5f5;
}

.clear {
  @include clearAfter();
}

.upload-button {
  label > * {
    pointer-events: none;
  }

  .upload-button-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -100000000;
  }
}

.label-designer {
  canvas {
    background: white !important;
    border: 1px solid lightgray !important; //override react-konva
    height: 4in;
    width: 10in;
  }
}
