.time-clock {
  width: 400px;

  td {
    width: 200px;
  }

  &__header {
    border-bottom: 1px solid $grey-2;
  }
}
