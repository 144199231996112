.release-notes {
  position: relative;
  width: 100%;
  height: auto;

  &:hover > .release-notes__edit {
    opacity: 1;
  }

  &__divider {
    @include MQ('L') {
      display: inline;
    }

    position: absolute;
    top: 68px;
    left: 155px;
    width: 5px;
    height: calc(100% - 67px);
    background-color: $grey-1;
    display: none;
  }

  &__timestamp {
    @include MQ('L') {
      top: 10px;
      left: 0;
      width: 160px;
      color: $grey;
    }

    position: absolute;
    top: 0;
    right: 20px;
    line-height: 40px;
    color: rgba($black, 0.65);
  }

  &__icon {
    @include MQ('L') {
      top: 40px;
      left: 117px;
    }

    position: absolute;
    top: 20px;
    left: 20px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: $grey;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 22px;
    color: $white;
    z-index: 2;
  }

  &__note {
    @include MQ('L') {
      padding: 20px 0 10px 180px;
    }

    padding: 10px 0;
    width: 100%;
    display: inline-block;

    &--title {
      font-weight: bold;
    }
  }

  &__mobile {
    @include MQ('L') {
      display: none;
    }

    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    height: 40px;
    background-color: $grey-1;
  }

  &__add {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($white, 1);
    z-index: 99;
    border-bottom: 1px solid $black;

    textarea {
      height: 200px;
      resize: none;
    }

    &--cancel {
      margin-right: 20px;
    }
  }

  &__edit {
    @include MQ('L') {
      top: 20px;
      right: 0;
    }

    position: absolute;
    top: 5px;
    right: -15px;
    width: 25px;
    height: 25px;
    font-size: 22px;
    color: rgba($grey, 0.75);
    opacity: 0;
    cursor: pointer;

    @include transition(all, 0.1s);
  }
}
