.profile-bar {
  position: absolute;
  top: 70px;
  left: 40px;

  i {
    // color: rgba($white, .75);
    color: $white;
    font-size: 24px;
    cursor: pointer;
    margin-right: 20px;
    opacity: 0.4;

    @include transition(all, 0.1s);

    &:hover {
      // color: $white;
      opacity: 1;
    }
  }
}
