.nav-main {
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 20px;

  @extend .open-sans;

  li {
  }

  a {
    display: block;
  }

  &__active {
    color: $white !important;
  }

  &__heading {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    overflow: hidden;
    cursor: pointer;

    &:hover {
      background-color: $sidebar-dark;
      color: $white;
    }

    a {
      padding: 14px 20px 14px 20px;
      color: rgba(255, 255, 255, 0.5);

      @include transition(background-color, 0.12s, ease-out);

      &:hover {
        background-color: $sidebar-dark;
        color: $white;
      }
    }
  }

  &__dashboard {
    // padding: 14px 20px 14px 20px;
  }
}
