.nav-submenu {
  padding: 0;
  list-style: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: $sidebar-dark;

  @include transition(all, 0.2s);

  li {
    padding: 10px 20px 10px 40px;
    text-transform: none;
    cursor: pointer;

    // display: block;
    color: rgba(255, 255, 255, 0.5);
    // padding: 0;

    @include transition(color, 0.12s, ease-out);

    &:hover {
      color: $white;
    }
  }

  a {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    padding: 0;

    @include transition(color, 0.12s, ease-out);

    &:hover {
      color: $white;
    }
  }

  &__active {
    color: $white !important;
  }
}

.nav-third {
  margin-left: 55px;
  // background-color: $grey;

  li {
    padding-left: 5px;
    list-style: none;
  }
}
