.sidebar2 {
  position: fixed;
  top: 100px;
  left: 0;
  width: 230px;
  color: $white;
  z-index: 999;

  @include transition(all, 0.2s);

  &__tablet {
    // left: -220px;

    background-color: #2b6e80 20%;
    background-image: radial-gradient(farthest-side ellipse at 10% 0, #2b6e80 20%, #0c3252);
    background-image: -webkit-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
    background-image: -moz-radial-gradient(10% 0, farthest-side ellipse, #2b6e80 20%, #0c3252);
    background-attachment: fixed, fixed;
  }

  &__main {
    z-index: 1;
    font-weight: 300;
    list-style: none;

    li {
      display: list-item;
      text-align: -webkit-match-parent;
      margin-bottom: 7px;
    }

    &--active {
      a {
        font-weight: 700;
      }
    }
  }

  &__link {
    padding: 10px 20px;

    @extend .sidebar-link;
  }
}

.sidebar-item {
  &__angle {
    // position: absolute;
    // right: 10px;
    font-size: 14px;
    opacity: 0.4;
    float: right;

    @include transition(all, 0.3s);
  }

  &__expanded {
    background-color: rgba($black, 0.1);
  }

  &__icon {
    width: 16px;
    margin-right: 8px;
    float: left;
    line-height: 20px;
    font-size: 14px;
    opacity: 0.4;
    text-align: right;
  }

  ul {
    height: 0;
    overflow: hidden;
    list-style: none;

    @include transition(height, 0.3s, ease-in-out);
  }
}

.sidebar-sub-item {
  margin-bottom: 0 !important;

  &__link {
    padding: 6px 20px 6px 50px;

    @extend .sidebar-link;
  }

  ul {
    height: 0;
    overflow: hidden;
    list-style: none;

    @include transition(height, 0.3s, ease-in-out);
  }
}

.sidebar-link {
  color: inherit;
  text-decoration: none;
  display: block;
  border-radius: 6px;
  cursor: pointer;

  @include transition(background-color, 0.1s);

  &:hover {
    background-color: rgba($black, 0.1);
  }
}

.rot-90 {
  @include rotate(-90deg);
}
