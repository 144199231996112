.header-dropdown {
  position: relative;
  height: 50%;

  &:hover > .header-dropdown__icon {
    opacity: 1;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 0;

    @include transition(all, 0.2s);
  }
}
