.a-menu {
  position: fixed;
  top: 110px;
  right: 0;
  width: 0;
  height: 100%;
  z-index: $action_menu;

  @include transition(all, 0.1s);

  &__toggle {
    position: absolute;
    top: -30px;
    left: -60px;
    width: 60px;
    height: 60px;
    cursor: pointer;

    &--block {
      @include center-center(absolute);

      width: 60px;
      height: 60px;
      opacity: 1;
      color: $grey;
      font-size: 46px;
      line-height: 60px;
      text-align: center;
      background-color: $white;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;

      @include box-shadow(0, 0, 10px, rgba($black, 0.4));
      @include transition(all, 0.6s);

      &:hover {
        color: $black;
        @include box-shadow(0, 0, 10px, rgba($black, 0.8));
      }
    }
  }

  &__block {
    position: absolute;
    top: -30px;
    left: 0;
    width: 240px;
    // height: calc(100% - 60px);
    background-color: $grey-1;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    @include transition(all, 0.4s);
    @include box-shadow(0, 0, 20px, rgba($black, 0.4));

    &--item {
      padding: 7px 10px;
      cursor: pointer;
      color: $grey-3;
      font-size: 16px;

      &:hover {
        color: $black;
      }
    }
  }

  &__close {
    width: 100%;
    height: 32px;
    cursor: pointer;
    border-bottom: 1px solid $grey-2;
    padding-left: 10px;
    line-height: 32px;
    font-size: 18px;
    color: $grey-3;

    @include transition(color, 0.1s);

    &:hover {
      color: $black;
    }

    i {
      margin-right: 10px;
    }
  }
}

.__openActionMenu {
  left: -240px;
}

.__hideCog {
  opacity: 0;
  width: 10px;
  height: 10px;
  font-size: 5px;
  line-height: 10px;
}
