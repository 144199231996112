.user-profile {
  &__toggle-password {
    cursor: pointer;
    color: $font-color;
    margin-bottom: 15px !important;

    @extend .form-control__label;

    @include transition(all, 0.2s);

    &:hover {
      color: $black;
    }
  }
}
