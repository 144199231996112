.sr {
  margin: 0 0 10px 0;

  &__label {
    position: relative;
    padding: 0;

    &--title {
      position: relative;
      width: 96%;
      padding-top: 0.5em;
      box-sizing: border-box;

      span {
        color: $red;
      }
    }
  }

  &__content {
    padding: 0;
    box-sizing: border-box;
    line-height: 38px;

    input {
      // box-sizing: border-box;
    }
  }

  &__picklist {
    padding-top: 20px;
    line-height: 2;
  }

  &__right {
    float: right;
  }
}
