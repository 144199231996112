.sidebar-toggle {
  position: absolute;
  top: 10px;
  left: 29px;

  width: 40px;
  height: 40px;
  background-color: rgba($grey, 0.5);
  border-radius: 3px;
  border: 1px solid rgba($grey, 0.8);
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  color: rgba($black, 0.75);
  display: inline-block;
  z-index: 1000;

  @include transition(all, 0.1s);

  @include MQBelow('XS') {
    opacity: 1;
  }

  &:hover {
    color: $black;

    @include box-shadow(0, 0, 8px, $grey);
  }
}
