.main {
  // position: fixed;
  // top: $header-height;
  // left: 0;
  // width: calc(100% - #{$sidebar-width} - 17px);
  // height: $header-height;
  // background-color: $white;

  // border-bottom: 1px solid;
  // border-color: rgba($grey-1, 0.9);

  @include MQ('L') {
    // left: $sidebar-width;
  }

  @include transition(all, 0.2s);

  &__container {
    position: relative;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    // z-index: $main;
  }
}

.main-container {
  // background-color: rgba($white, 0)!important;
}

/*
 *  STYLE 7
 */

// .main-container::-webkit-scrollbar-track {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	background-color: rgba(#F5F5F5, 0);
// 	border-radius: 10px;
// }

// .main-container::-webkit-scrollbar {
// 	width: 10px;
// 	background-color: rgba($white, 0);
// }

// .main-container::-webkit-scrollbar-thumb {
// 	border-radius: 10px;
// 	background-color: $white;
// 	// background-image: -webkit-gradient(linear,
// 	// 								   left bottom,
// 	// 								   left top,
// 	// 								   color-stop(0.44, rgb(122,153,217)),
// 	// 								   color-stop(0.72, rgb(73,125,189)),
// 	// 								   color-stop(0.86, rgb(28,58,148)));
// }
